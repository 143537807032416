import { useLayoutEffect, useRef } from "react";
import { useIntersectionObserver } from "../components/common/IntersectionObsereverProvider";
import { trackingService } from "../services/action-tracking.service";

// Works only inside <IntersectionObserverProvider>
export function useContentVisibilityLogger(
    targetRef: React.RefObject<HTMLElement>,
    traceText?: string,
    visibleTimoutSeconds: number = 5
) {
    const { id, visible } = useIntersectionObserver(traceText ? targetRef : undefined);
    const visibleRef = useRef(visible);
    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    useLayoutEffect(() => {
        visibleRef.current = visible;
        if (visible && traceText) {
            timeout.current = setTimeout(() => {
                if (visibleRef.current && traceText) {
                    trackingService.trackAction(traceText);
                }
            }, visibleTimoutSeconds * 1000);
        } else if(timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }
        //
    }, [traceText, visible, visibleTimoutSeconds])

    return { id, visible };
}